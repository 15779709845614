* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  scroll-behavior: smooth;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: #011C30;
    overflow-x: hidden;
}

button {
  border: none;
  background: none;
}

a {
  color: map-get($mainColor, "main-font-color");
  text-decoration: none;
}

ul {
  list-style: none;
}

input, textarea {
  border: none;
  background: none;
}

.container {
  padding: 0 15px;
  max-width: 1200px;
  margin: 0 auto;
}

.line_element {
  height: 2px;
  background-size: 300% 100%;
  animation: line-element 6s linear infinite;
  margin: 12px 0;
  filter: drop-shadow(0px 16px 30px #184ba9);
  border-radius: 12px;
  background-image: linear-gradient(
    90deg,
    rgb(206, 206, 206),
    #184ba9,
    #ffdd45,
    #184ba9
  );
}

.app {
  padding-top: 140px;
  padding-bottom: 80px;
}

@media screen and (max-width: 767.98px) {
  .mobile-hidden-2 {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .mobile_hidden {
    display: none !important;
  }
}