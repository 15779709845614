@keyframes line-element {
  0% {
    background-position: 100% 0%;
  }
  50% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes rotate-1 {
  0% {
    transform: rotate(0) translateY(0);
  }
  50% {
    transform: rotate(50deg) translateY(0);
  }
  100% {
    transform: rotate(0) translateY(0);
  }
}
@keyframes rotate-2 {
  0% {
    transform: rotate(0) translateY(0);
  }
  50% {
    transform: rotate(-50deg) translateY(0);
  }
  100% {
    transform: rotate(0) translateY(0);
  }
}
@keyframes scale-1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scale-2 {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes full-rotate-1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes full-rotate-2 {
  0% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

html,
body {
  scroll-behavior: smooth;
  font-family: "Helvetica Neue", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: #011C30;
  overflow-x: hidden;
}

button {
  border: none;
  background: none;
}

a {
  color: #fff;
  text-decoration: none;
}

ul {
  list-style: none;
}

input, textarea {
  border: none;
  background: none;
}

.container {
  padding: 0 15px;
  max-width: 1200px;
  margin: 0 auto;
}

.line_element {
  height: 2px;
  background-size: 300% 100%;
  animation: line-element 6s linear infinite;
  margin: 12px 0;
  filter: drop-shadow(0px 16px 30px #184ba9);
  border-radius: 12px;
  background-image: linear-gradient(90deg, rgb(206, 206, 206), #184ba9, #ffdd45, #184ba9);
}

.app {
  padding-top: 140px;
  padding-bottom: 80px;
}

@media screen and (max-width: 767.98px) {
  .mobile-hidden-2 {
    display: none !important;
  }
}
@media screen and (max-width: 576px) {
  .mobile_hidden {
    display: none !important;
  }
}
.section-hero {
  height: calc(100vh - 250px);
}
.section-hero .container {
  position: relative;
  height: 100%;
}
.section-hero .container .content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  padding: 0 48px;
  background-image: url(../assets/images/background/hero.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}
.section-hero .container .content h1 {
  color: #fff;
  font-size: 52px;
  font-weight: 700;
  line-height: 61px;
  max-width: 650px;
}
.section-hero .container .content p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 24px;
  margin-bottom: 32px;
  max-width: 650px;
}
.section-hero .container .content a {
  display: inline-block;
  padding: 8px 24px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  transition: all 0.3s ease-in;
  border-radius: 10px;
  background-color: #ffdd45;
  border: 1px solid transparent;
}
.section-hero .container .content a:hover {
  color: #ffdd45;
  border: 1px solid #ffdd45;
  background-color: transparent;
}
.section-hero .container .youtube-wrapper-1 {
  position: absolute;
  top: 101px;
  right: 370px;
  z-index: 99;
  animation: scale-1 6s ease-in-out infinite;
}
.section-hero .container .youtube-wrapper-2 {
  position: absolute;
  top: 241px;
  right: 173px;
  z-index: 99;
  transform: rotate(30deg);
  animation: scale-2 6s ease-in-out infinite;
}
.section-hero .container .youtube-wrapper-3 {
  position: absolute;
  bottom: 141px;
  right: 50%;
  z-index: 99;
  transform: rotate(45deg);
  animation: scale-1 6s ease-in-out infinite;
}
.section-hero .container .bitcoin-wrapper-1 {
  position: absolute;
  top: 20px;
  right: 135px;
  animation: rotate-2 6s ease-in-out infinite;
}
.section-hero .container .bitcoin-wrapper-2 {
  position: absolute;
  bottom: 147px;
  right: 432px;
  animation: rotate-1 6s ease-in-out infinite;
}
.section-hero .container .ethereum-wrapper-1 {
  position: absolute;
  top: -70px;
  right: 500px;
  animation: scale-1 6s ease-in-out infinite;
}
.section-hero .container .ethereum-wrapper-2 {
  position: absolute;
  bottom: 110px;
  right: 158px;
  animation: scale-1 6s ease-in-out infinite;
}
.section-hero .container .ethereum-wrapper-second-1 {
  position: absolute;
  bottom: -120px;
  right: 480px;
  animation: rotate-2 9s ease-in-out infinite;
}
.section-hero .container .ethereum-wrapper-second-2 {
  position: absolute;
  top: 175px;
  right: 50px;
  width: 100px;
  height: 100px;
  animation: scale-2 6s ease-in-out infinite;
}
.section-hero .container .ethereum-wrapper-second-2 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 768.98px) {
  .section-hero .content {
    padding: 0 14px !important;
    background-position: left !important;
  }
  .section-hero .content h1 {
    font-size: 32px !important;
    line-height: 40px !important;
  }
  .section-hero .content a {
    text-align: center;
    width: 100%;
  }
  .section-hero .bitcoin-wrapper-1 {
    top: 10px !important;
    right: 10px !important;
  }
  .section-hero .ethereum-wrapper-second-1 {
    bottom: -90px !important;
    left: -10px !important;
  }
}
.section-benefits {
  padding: 90px 0;
}
.section-benefits .content {
  position: relative;
  display: flex;
  flex-direction: column;
}
.section-benefits .content .ethereum_wrapper {
  position: absolute;
  top: 260px;
  left: -20px;
  animation: rotate-1 6s ease-in-out infinite;
}
.section-benefits .content .bitcoin_wrapper {
  position: absolute;
  top: 100px;
  right: -10px;
  animation: rotate-2 6s ease-in-out infinite;
}
.section-benefits .content .info-text span {
  display: inline-block;
  color: #60717d;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
}
.section-benefits .content .info-text h3 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  margin-top: 12px;
  margin-bottom: 38px;
}
.section-benefits .content .body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
}
.section-benefits .content .body .item {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid transparent;
  border-radius: 12px;
  background: linear-gradient(125deg, rgba(25, 64, 171, 0.13) 1.51%, rgba(22, 61, 169, 0) 95.34%);
  -webkit-backdrop-filter: blur(37.2034301758px);
          backdrop-filter: blur(37.2034301758px);
  padding: 35px 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.section-benefits .content .body .item:hover {
  transform: translateY(-10px);
  border: 0.5px solid #286497;
}
.section-benefits .content .body .item h2 {
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}
.section-benefits .content .body .item p {
  color: #dedede;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 768.98px) {
  .section-benefits .content .bitcoin_wrapper {
    top: 120px !important;
    right: -50px !important;
  }
  .section-benefits .content .ethereum_wrapper {
    top: unset !important;
    bottom: -170px !important;
    left: -20px;
  }
  .section-benefits .content .body .item {
    flex: 1 1 320px !important;
    max-width: unset !important;
  }
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.services-amount-body {
  padding-top: 200px;
  padding-bottom: 150px;
}
.services-amount-body .container {
  position: relative;
}
.services-amount-body .container .bitcoin_wrapper,
.services-amount-body .container .ethereum_wrapper {
  position: absolute;
}
.services-amount-body .container .bitcoin_wrapper {
  left: 0;
  top: 60px;
  animation: rotate-1 6s ease-in-out infinite;
}
.services-amount-body .container .ethereum_wrapper {
  bottom: 20px;
  right: 0;
  animation: rotate-2 6s ease-in-out infinite;
}
.services-amount-body .services-amount-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  gap: 24px;
  flex-wrap: wrap;
}
.services-amount-body .services-amount-wrapper .item-container {
  z-index: 99;
  padding: 25px 50px;
  flex: 1 1 200px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
  background: rgba(31, 39, 46, 0.2);
  box-shadow: 0px 37px 115px 0px rgba(0, 0, 0, 0.25) inset, 0px -17px 92px 0px rgba(255, 255, 255, 0.05) inset;
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.services-amount-body .services-amount-wrapper .item-container:hover {
  transform: translateY(-8px) !important;
}
@media screen and (max-width: 768px) {
  .services-amount-body .services-amount-wrapper .item-container {
    justify-content: center;
  }
}
.services-amount-body .services-amount-wrapper .item-container h3 {
  display: flex;
  gap: 12px;
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 10px;
}
.services-amount-body .services-amount-wrapper .item-container h3 p {
  color: #ffdd45;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
}
@media screen and (max-width: 576px) {
  .services-amount-body .services-amount-wrapper .item-container h3 {
    font-size: 26px;
  }
}
.services-amount-body .services-amount-wrapper .item-container p {
  color: #666;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  text-wrap: nowrap;
}

@media screen and (max-width: 768.98px) {
  .services-amount-body {
    padding-top: 120px;
    padding-bottom: 70px;
  }
  .services-amount-body .bitcoin_wrapper {
    top: -60px !important;
    left: -50px !important;
  }
  .services-amount-body .ethereum_wrapper {
    bottom: -120px !important;
    right: -10px !important;
  }
}
.section-amount-main {
  padding: 90px 0;
}
.section-amount-main .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
}
.section-amount-main .content-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  flex: 1 1 450px;
  padding: 65px;
  border-radius: 12px;
  background: rgba(31, 39, 46, 0.2);
  box-shadow: 0px 37px 115px 0px rgba(0, 0, 0, 0.25) inset, 0px -17px 92px 0px rgba(255, 255, 255, 0.05) inset;
  -webkit-backdrop-filter: blur(12px);
          backdrop-filter: blur(12px);
  position: relative;
}
.section-amount-main .content-item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, rgba(92, 107, 192, 0.02) 0%, #163e9a 30.5%, #252d9e 68.5%, rgba(116, 178, 205, 0) 96%);
  filter: drop-shadow(0px 16px 30px #184ba9);
}
.section-amount-main .content-item p {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.section-amount-main .content-item p span {
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
}
.section-amount-main .content-item p span:first-child {
  color: #fff !important;
}
.section-amount-main .content-item p span:last-child {
  color: #ffdd45;
}
.section-amount-main .content-item p {
  color: #666;
  font-size: 26px;
  font-weight: 400;
  line-height: 44px;
  text-align: center;
}

@media screen and (max-width: 768.98px) {
  .section-amount-main .content-item {
    padding: 40px 0 !important;
  }
}
.section-information {
  padding: 40px 0;
}
.section-information .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
}
.section-information .content-left {
  flex: 1 1 450px;
}
.section-information .content-left span {
  color: #60717d;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
}
.section-information .content-left h2 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  margin-top: 12px;
  margin-bottom: 65px;
}
.section-information .content-left ul {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.section-information .content-left ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}
.section-information .content-left ul li span {
  position: relative;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
  transition: all 0.3s ease-in;
}
.section-information .content-left ul li span::after {
  position: absolute;
  left: -45px;
  top: 60px;
  content: "";
  width: 3px;
  height: 70px;
  background: linear-gradient(90deg, #274bb0 0%, #10204a 100%);
}
.section-information .content-left ul li:last-child span::after {
  display: none;
}
.section-information .content-right {
  flex: 1 1 450px;
  margin-top: 180px;
}
.section-information .content-right img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 768.98px) {
  .section-information .content-left ul {
    gap: 70px;
  }
  .section-information .content-left ul li span::after {
    top: 55px;
    height: 50px;
  }
  .section-information .content-right {
    margin-top: 80px;
  }
}
.section-customer {
  padding-top: 90px;
  padding-bottom: 50px;
}
.section-customer .reviews-header span {
  color: #546673;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
}
.section-customer .reviews-header h2 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  margin-top: 12px;
  margin-bottom: 38px;
}
.section-customer .reviews-body .swiper-container {
  padding-top: 100px;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
}
.section-customer .reviews-body .swiper-container .swiper-slide {
  position: relative;
  border-radius: 12px;
  background: #25375a;
  padding: 55px 16px;
  -webkit-backdrop-filter: blur(37.2034301758px);
          backdrop-filter: blur(37.2034301758px);
}
.section-customer .reviews-body .swiper-container .swiper-slide img {
  position: absolute;
  width: 98px;
  height: 98px;
  top: -32%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 9999;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info h2 {
  color: #ffdd45;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info p {
  margin: 15px 0;
  color: var(--White-medium, rgba(255, 255, 255, 0.87));
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.section-customer .reviews-body .swiper-container .swiper-slide .user-info .rate {
  color: #ffdd45;
}
.section-customer .reviews-body .swiper-container .swiper-pagination {
  position: absolute;
  bottom: 5px;
}
.section-customer .reviews-body .swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
}
.section-customer .reviews-body .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #0f3765;
  border: 3px solid #011c30;
  outline: 2px solid #0f3765;
}
.section-customer .reviews-body .swiper-container .swiper-slide {
  opacity: 0.4;
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.section-customer .reviews-body .swiper-container .swiper-slide-active {
  opacity: 1;
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border: 0.7px solid rgba(255, 221, 69, 0.5176470588);
}

.section-partners {
  padding: 90px 0;
}
.section-partners h2 {
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  margin-bottom: 60px;
}
.section-partners .swiper-slide {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-direction: column;
  padding: 56px 20px;
  border-radius: 12px;
  background: linear-gradient(162deg, #162136 6.7%, #405f9c 208.25%);
  cursor: pointer;
}
.section-partners .swiper-slide img {
  height: 120px;
  width: 120px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.section-partners .swiper-slide p {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
}

.section-agents-contacts {
  padding: 110px 0;
}
.section-agents-contacts .title-container {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 60px auto;
}
.section-agents-contacts .title-container h2 {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  padding: 0 12px;
}
.section-agents-contacts .agents-contacts {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}
.section-agents-contacts .agents-contacts-item {
  flex: 1 1 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 12px;
  background: rgba(40, 100, 151, 0.13);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.section-agents-contacts .agents-contacts-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}
.section-agents-contacts .agents-contacts-item a img {
  padding: 16px;
  border-radius: 50px;
  background: linear-gradient(148deg, #233454 18.4%, #4e73ba 118.73%);
}
.section-agents-contacts .agents-contacts-item a span {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}

.footer .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 60px;
  border-top: 0.3px solid rgba(255, 255, 255, 0.6);
}
.footer .footer-content .logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.footer .footer-content .logo-container img {
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
}
.footer .footer-content .logo-container .footer-short-info {
  max-width: 400px;
  color: #fff;
}
.footer .footer-content .footer-contact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}
.footer .footer-content .footer-contact .contact-item-info {
  display: flex;
  flex-direction: column;
}
.footer .footer-content .footer-contact .contact-item-info h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
}
.footer .footer-content .footer-contact .contact-item-info a {
  display: flex;
  gap: 6px;
  transition: 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.footer .footer-content .footer-contact .contact-item-info a:hover {
  color: #ffb629;
}
.footer .footer-content .footer-contact .contact-item-info a span {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.footer .footer-content .footer-contact .footer-social {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}
.footer .footer-content .footer-contact .footer-social a {
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
     object-fit: cover;
  transition: 0.3s ease-in-out;
}
.footer .footer-content .footer-contact .footer-social a:hover {
  color: #ffb629;
}
.footer .footer-content .footer-contact .footer-social a img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  transition: all 0.6s ease;
  padding: 19px 0;
}
.header.fixed {
  background-color: rgba(2, 28, 48, 0.7);
  padding: 13px 0;
  border-bottom: 0.2px solid rgba(255, 255, 255, 0.443);
}
.header .header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .header_container .mobile_btn_open img {
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: 7px;
}
.header .header_container .logo_container {
  width: 65px;
  height: 65px;
  -o-object-fit: cover;
     object-fit: cover;
}
.header .header_container .logo_container img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.header .header_container .nav_container ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 44px;
}
.header .header_container .nav_container_item {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
}
.header .header_container .nav_container_item:hover {
  color: #ffe882;
}
.header .header_container .nav_container_item.active {
  color: #FFE882;
}
.header .header_container .contact_container {
  transition: 0.2s ease-in;
}
.header .header_container .contact_container:hover {
  transform: scale(1.1);
}
.header .header_container .contact_container a {
  font-size: 40px;
}
.header .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  padding: 35px 25px;
  background: linear-gradient(120deg, rgba(25, 64, 171, 0.13) 1.37%, rgba(22, 61, 169, 0) 103.77%);
  -webkit-backdrop-filter: blur(37.2034301758px);
          backdrop-filter: blur(37.2034301758px);
  transition: all 0.3s ease-out;
  transform: translateX(-100vw);
}
.header .sidebar a {
  color: rgba(255, 255, 255, 0.6);
}
.header .sidebar .sidebar_body {
  margin-top: 120px;
}
.header .sidebar .sidebar_body li {
  padding: 15px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
.header .sidebar .sidebar_body li a {
  color: #fff;
  text-transform: uppercase;
}
.header .sidebar .sidebar_body li a.active {
  color: #FFE882 !important;
}

#show .sidebar {
  transform: translateX(0vw);
  min-width: 80%;
}
#show .sidebar_header .close_button {
  font-size: 31px;
  color: #fff;
  float: right;
}
#show .sidebar .menu-inner {
  top: 0;
  height: calc(100% - 50px);
  padding-top: 50px;
}
#show .sidebar .menu-inner .menu-item {
  padding: 40px 30px;
}

@media (max-width: 1200px) {
  .mobile_btn_open {
    display: block;
  }
  .nav_container {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .mobile_btn_open {
    display: none !important;
  }
}
.about-page .title-container {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 60px auto;
}
.about-page .title-container h1 {
  color: #fff;
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  padding: 0 12px;
  text-align: center;
}
.about-page-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about-page-body img {
  width: 100%;
  max-height: 600px;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-page-body .main-content-wrapper {
  text-align: center;
}
.about-page-body .main-content-wrapper p {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 20px;
}
.about-page-body .main-content-wrapper .main-content p {
  color: #dedede;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

@media screen and (max-width: 768.98px) {
  .about-page .title-container {
    margin: 0 auto 30px auto;
  }
  .about-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .about-page .main-content {
    text-align: justify;
  }
  .about-page-body img {
    min-height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.contact-page .title-container {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 60px auto;
}
.contact-page .title-container h1 {
  color: #fff;
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  padding: 0 12px;
  text-align: center;
}
.contact-page .contact-form {
  border-radius: 12px;
  background: rgba(40, 100, 151, 0.13);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  padding: 40px 50px;
}
.contact-page .contact-form form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact-page .contact-form form .input-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1 1 300px;
}
.contact-page .contact-form form .input-item label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}
.contact-page .contact-form form .input-item label span {
  color: #ffdd45;
  margin-left: 6px;
  margin-top: 6px;
}
.contact-page .contact-form form .form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
.contact-page .contact-form form .form-group input {
  padding: 15px;
  color: #fff;
  transition: 0.3s ease-in;
  border-radius: 12px;
  border: 1px solid #4e4e4f;
}
.contact-page .contact-form form .form-group input::-moz-placeholder {
  color: #dedede;
}
.contact-page .contact-form form .form-group input::placeholder {
  color: #dedede;
}
.contact-page .contact-form form .form-group input:focus {
  border: 1px solid #bababa;
}
.contact-page .contact-form form .message textarea {
  padding: 15px;
  color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  border: 1px solid #4e4e4f;
}
.contact-page .contact-form form .message textarea::-moz-placeholder {
  color: #dedede;
}
.contact-page .contact-form form .message textarea::placeholder {
  color: #dedede;
}
.contact-page .contact-form form .message textarea:focus {
  border: 1px solid #bababa;
}
.contact-page .contact-form form input[type=submit] {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 30px auto 0 auto;
  padding: 12px 48px;
  border-radius: 12px;
  background: linear-gradient(127deg, #ffb629 0%, #ffda56 50.72%, #ffd7a6 100%);
}

@media screen and (max-width: 768.98px) {
  .contact-page .title-container {
    margin: 0 auto 30px auto;
  }
  .contact-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .contact-page .contact-form {
    padding: 20px 12px;
  }
  .contact-page .contact-form input[type=submit] {
    margin: unset !important;
  }
  .contact-page .section-agents-contacts {
    padding-top: 110px;
    padding-bottom: 40px;
  }
  .contact-page .section-agents-contacts .agents-contacts-item {
    padding: 30px;
  }
}
.influencers-page .title-container {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 60px auto;
}
.influencers-page .title-container h1 {
  color: #fff;
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  padding: 0 12px;
  text-align: center;
}
.influencers-page .influencers-wrapper {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}
.influencers-page .influencers-wrapper .influencer-item {
  border-radius: 12px;
  background: rgba(40, 100, 151, 0.13);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  padding: 24px;
  transition: 0.25s ease-in;
  border: 0.5px solid transparent;
}
.influencers-page .influencers-wrapper .influencer-item:hover {
  border: 0.5px solid #286497;
}
.influencers-page .influencers-wrapper .influencer-item-image {
  height: 230px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}
.influencers-page .influencers-wrapper .influencer-item-content {
  display: flex;
  flex-direction: column;
}
.influencers-page .influencers-wrapper .influencer-item-content .name {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 16px;
}
.influencers-page .influencers-wrapper .influencer-item-content .count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 20px;
}
.influencers-page .influencers-wrapper .influencer-item-content .count span {
  color: #ffe882;
}
.influencers-page .influencers-wrapper .influencer-item-content .buttons {
  display: flex;
  text-align: center;
}
.influencers-page .influencers-wrapper .influencer-item-content .buttons a {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-wrap: nowrap;
  flex: 1 1 100px;
  padding: 8px 12px;
  background: linear-gradient(127deg, #ffb629 0%, #ffda56 50.72%, #ffd7a6 100%);
}
.influencers-page .influencers-wrapper .influencer-item-content .buttons a:first-child {
  border-radius: 12px 0px 0px 12px;
  border-right: 1px solid #18222d;
}
.influencers-page .influencers-wrapper .influencer-item-content .buttons a:last-child {
  border-radius: 0px 12px 12px 0px;
}

@media screen and (max-width: 768.98px) {
  .influencers-page .title-container {
    margin: 0 auto 30px auto;
  }
  .influencers-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
}
.section-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.section-not-found .error-body {
  position: relative;
  flex-direction: column;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.section-not-found .error-body h1 {
  color: #fff;
  font-size: 128px;
  font-weight: 700;
}
.section-not-found .error-body p {
  color: #fff;
  margin-top: 12px;
  margin-bottom: 24px;
  font-size: 32px;
}
.section-not-found .error-body .btn-return {
  font-size: 18px;
  text-transform: uppercase;
  display: block;
  padding: 14px;
  border-radius: 12px;
  background-color: rgba(255, 221, 69, 0.8784313725);
  max-width: 200px;
  margin: 0 auto;
  transition: 0.25s;
  border: 1px solid transparent;
  z-index: 9999;
  position: relative;
  opacity: 0.95;
}
.section-not-found .error-body .btn-return:hover {
  color: #ffdd45;
  background-color: transparent;
  border: 1px solid #ffdd45;
}
.section-not-found .error-body .ethereum-wrapper, .section-not-found .error-body .bitcoin-wrapper, .section-not-found .error-body .ethereum-wrapper-2 {
  position: absolute;
  width: 150px;
  height: 150px;
}
.section-not-found .error-body .ethereum-wrapper img, .section-not-found .error-body .bitcoin-wrapper img, .section-not-found .error-body .ethereum-wrapper-2 img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.section-not-found .error-body .ethereum-wrapper {
  top: -60px;
  left: -50px;
  animation: scale-1 6s ease-in-out infinite;
}
.section-not-found .error-body .bitcoin-wrapper {
  top: -60px;
  right: -50px;
  animation: rotate-1 6s ease-in-out infinite;
}
.section-not-found .error-body .ethereum-wrapper-2 {
  left: 190px;
  bottom: -90px;
  animation: rotate-1 6s ease-in-out infinite;
}

.services-page .title-container {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 60px auto;
}
.services-page .title-container h1 {
  color: #fff;
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  padding: 0 12px;
  text-align: center;
}
.services-page .title-container p {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  padding-top: 12px;
  text-align: center;
}
.services-page .main-content {
  display: flex;
  row-gap: 60px;
  flex-wrap: wrap;
  margin-top: 120px;
}
.services-page .main-content .service-card {
  flex: 1 1 270px;
  text-align: center;
  padding: 0 20px;
  transition: 0.3s;
}
.services-page .main-content .service-card i {
  font-size: 70px;
  color: #0c6183;
}
.services-page .main-content .service-card img {
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
     object-fit: contain;
}
.services-page .main-content .service-card h2 {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  padding-top: 12px;
  padding-bottom: 18px;
  min-height: 80px;
}
.services-page .main-content .service-card p {
  color: #dedede;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 28px;
}
.services-page .main-content .service-card span {
  font-size: 14px;
  font-weight: 400;
  color: #498ea9;
}

@media screen and (max-width: 768.98px) {
  .services-page .title-container {
    margin: 0 auto 30px auto;
  }
  .services-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .services-page .main-content .service-card h2 {
    min-height: unset !important;
  }
}
.statistics-page .title-container {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 60px auto;
}
.statistics-page .title-container h1 {
  color: #fff;
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  padding: 0 12px;
  text-align: center;
}
.statistics-page .main-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 25px;
}
.statistics-page .main-content-left {
  flex: 1 1;
  min-width: 250px;
}
.statistics-page .main-content-left .influencer-item {
  border-radius: 12px;
  border: 1px solid #0e0024;
  background: rgba(40, 100, 151, 0.13);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  padding: 24px;
  border: 0.5px solid transparent;
}
.statistics-page .main-content-left .influencer-item:hover {
  border: 0.5px solid #286497;
}
.statistics-page .main-content-left .influencer-item-image {
  height: 230px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}
.statistics-page .main-content-left .influencer-item-content {
  display: flex;
  flex-direction: column;
}
.statistics-page .main-content-left .influencer-item-content .name {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 16px;
}
.statistics-page .main-content-left .influencer-item-content .count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 20px;
}
.statistics-page .main-content-left .influencer-item-content .count span {
  color: #ffe882;
}
.statistics-page .main-content-left .influencer-item-content .buttons {
  display: flex;
  text-align: center;
}
.statistics-page .main-content-left .influencer-item-content .buttons a {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  flex: 1 1 100px;
  padding: 8px 12px;
  border-radius: 12px;
  background: linear-gradient(127deg, #ffb629 0%, #ffda56 50.72%, #ffd7a6 100%);
}
.statistics-page .main-content-right {
  flex: 3 1;
  border-radius: 12px;
  background: rgba(40, 100, 151, 0.13);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  padding: 24px 24px 12px 24px;
}
.statistics-page .main-content-right .statistics-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents {
  flex: 1 1 250px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  padding: 0 6px 12px 6px;
  border-bottom: 0.5px solid #4e4e4f;
  text-wrap: nowrap;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li .country-wrapper {
  display: flex;
  gap: 8px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li img {
  width: 24px;
  height: 24px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul li p {
  color: rgba(255, 255, 255, 0.87);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.statistics-page .main-content-right .statistics-wrapper .statistics-contents ul p {
  text-align: center;
  color: #ffdd45;
  margin-bottom: 12px;
}
.statistics-page .other-influencers {
  margin-top: 50px;
}
.statistics-page .other-influencers .title-container {
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto 60px auto;
}
.statistics-page .other-influencers .title-container h1 {
  color: #fff;
  font-size: 58px;
  font-weight: 700;
  line-height: 68px;
  padding: 0 12px;
  text-align: center;
}
.statistics-page .other-influencers-wrapper {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}
.statistics-page .other-influencers-wrapper-item {
  border-radius: 12px;
  border: 0.5px solid #0e0024;
  background: rgba(40, 100, 151, 0.13);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  padding: 24px;
}
.statistics-page .other-influencers-wrapper-item:hover {
  border: 0.5px solid #286497;
}
.statistics-page .other-influencers-wrapper-item-image {
  height: 230px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
}
.statistics-page .other-influencers-wrapper-item-content {
  display: flex;
  flex-direction: column;
}
.statistics-page .other-influencers-wrapper-item-content .name {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 16px;
}
.statistics-page .other-influencers-wrapper-item-content .count {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 20px;
}
.statistics-page .other-influencers-wrapper-item-content .count span {
  color: #ffe882;
}
.statistics-page .other-influencers-wrapper-item-content .buttons {
  display: flex;
  text-align: center;
}
.statistics-page .other-influencers-wrapper-item-content .buttons a {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-wrap: nowrap;
  flex: 1 1 100px;
  padding: 8px 12px;
  background: linear-gradient(127deg, #ffb629 0%, #ffda56 50.72%, #ffd7a6 100%);
}
.statistics-page .other-influencers-wrapper-item-content .buttons a:first-child {
  border-radius: 12px 0px 0px 12px;
  border-right: 1px solid #18222d;
}
.statistics-page .other-influencers-wrapper-item-content .buttons a:last-child {
  border-radius: 0px 12px 12px 0px;
}

@media screen and (max-width: 768.98px) {
  .statistics-page .title-container {
    margin: 0 auto 30px auto;
  }
  .statistics-page .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .statistics-page .other-influencers .title-container {
    margin: 0 auto 30px auto;
  }
  .statistics-page .other-influencers .title-container h1 {
    font-size: 32px;
    line-height: 40px;
  }
}
.page-main {
  padding-top: 100px;
}/*# sourceMappingURL=main.css.map */