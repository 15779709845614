.footer {
  .footer-content {
    @include flexbox(space-between, center);
    gap: 30px;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 60px;
    border-top: 0.3px solid rgba(255, 255, 255, 0.6);
    .logo-container {
      @include flexbox(flex-start, flex-start);
      flex-wrap: wrap;
      gap: 20px;
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
      .footer-short-info {
        max-width: 400px;
        color: #fff;
      }
    }

    .footer-contact {
      @include flexbox(center, flex-start);
      flex-direction: column;
      gap: 30px;
      .contact-item-info {
        display: flex;
        flex-direction: column;
        h3 {
          color: #fff;
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
          margin-bottom: 8px;
        }
        a {
          display: flex;
          gap: 6px;
          transition: 0.3s ease-in-out;
          font-size: 14px;
          font-weight: 400;
          color: map-get($mainColor, main-font-color);
          &:hover {
            color: #ffb629;
          }
          span {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
          }
        }
      }
      .footer-social {
        @include flexbox(flex-start, flex-start);
        gap: 12px;
        a {
          width: 24px;
          height: 24px;
          object-fit: cover;
          transition: 0.3s ease-in-out;
          &:hover {
            color: #ffb629;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
