.section-not-found {
  @include flexbox(center, center);
  height: 80vh;
  .error-body {
    position: relative;
    flex-direction: column;
    text-align: center;
    width: fit-content;
    height: fit-content;
    h1 {
      color: #fff;
      font-size: 128px;
      font-weight: 700;
    }
    p {
      color: #fff;
      margin-top: 12px;
      margin-bottom: 24px;
      font-size: 32px;
    }
    .btn-return {
      font-size: 18px;
      text-transform: uppercase;
      display: block;
      padding: 14px;
      border-radius: 12px;
      background-color: #ffdd45e0;
      max-width: 200px;
      margin: 0 auto;
      transition: 0.25s;
      border: 1px solid transparent;
      z-index: 9999;
      position: relative;
      opacity: 0.95;
      &:hover {
        color: #ffdd45;
        background-color: transparent;
        border: 1px solid #ffdd45;
      }
    }
    .ethereum-wrapper, .bitcoin-wrapper, .ethereum-wrapper-2 {
      position: absolute;
      width: 150px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .ethereum-wrapper {
      top: -60px;
      left: -50px;
      animation: scale-1 6s ease-in-out infinite;
    }
    .bitcoin-wrapper {
      top: -60px;
      right: -50px;
      animation: rotate-1 6s ease-in-out infinite;
    }
    .ethereum-wrapper-2  {
      left: 190px;
      bottom: -90px;
      animation: rotate-1 6s ease-in-out infinite;
    }
  }
}
