.statistics-page {
  .title-container {
    max-width: fit-content;
    margin: 0 auto 60px auto;
    h1 {
      color: map-get($mainColor, main-font-color);
      font-size: 58px;
      font-weight: 700;
      line-height: 68px;
      padding: 0 12px;
      text-align: center;
    }
  }

  .main-content {
    @include flexbox(space-between, flex-start);
    flex-wrap: wrap;
    gap: 25px;

    &-left {
      flex: 1 1;
      min-width: 250px;
      .influencer-item {
        border-radius: 12px;
        border: 1px solid #0e0024;
        background: rgba(40, 100, 151, 0.13);
        backdrop-filter: blur(25px);
        padding: 24px;
        border: 0.5px solid transparent;
        &:hover {
          border: 0.5px solid #286497;
        }
        &-image {
          height: 230px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 12px;
        }
        &-content {
          display: flex;
          flex-direction: column;
          .name {
            color: map-get($mainColor, main-font-color);
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            margin-top: 16px;
          }
          .count {
            @include flexbox(flex-start, center);
            gap: 8px;
            color: map-get($mainColor, main-font-color);
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            margin-top: 8px;
            margin-bottom: 20px;
            span {
              color: #ffe882;
            }
          }

          .buttons {
            display: flex;
            text-align: center;
            a {
              color: rgba(0, 0, 0, 0.87);
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              flex: 1 1 100px;
              padding: 8px 12px;
              border-radius: 12px;
              background: linear-gradient(
                127deg,
                #ffb629 0%,
                #ffda56 50.72%,
                #ffd7a6 100%
              );
            }
          }
        }
      }
    }

    &-right {
      flex: 3 1;
      border-radius: 12px;
      background: rgba(40, 100, 151, 0.13);
      backdrop-filter: blur(25px);
      padding: 24px 24px 12px 24px;
      .statistics-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        .statistics-contents {
          flex: 1 1 250px;
          ul {
            li {
              @include flexbox(space-between, center);
              gap: 12px;
              margin-bottom: 20px;
              padding: 0 6px 12px 6px;
              border-bottom: 0.5px solid #4e4e4f;
              text-wrap: nowrap;
              .country-wrapper {
                display: flex;
                gap: 8px;
              }
              img {
                width: 24px;
                height: 24px;
                object-fit: cover;
                border-radius: 50%;
              }
              span {
                color: map-get($mainColor, main-font-color);
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
              }
              p {
                color: rgba(255, 255, 255, 0.87);
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
              }
            }
            p {
              text-align: center;
              color: map-get($mainColor, main-font-color-third);
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }

  .other-influencers {
    margin-top: 50px;
    .title-container {
      max-width: fit-content;
      margin: 0 auto 60px auto;
      h1 {
        color: map-get($mainColor, main-font-color);
        font-size: 58px;
        font-weight: 700;
        line-height: 68px;
        padding: 0 12px;
        text-align: center;
      }
    }

    &-wrapper {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      &-item {
        border-radius: 12px;
        border: 0.5px solid #0e0024;
        background: rgba(40, 100, 151, 0.13);
        backdrop-filter: blur(25px);
        padding: 24px;
        &:hover {
          border: 0.5px solid #286497;
        }
        &-image {
          height: 230px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 12px;
        }
        &-content {
          display: flex;
          flex-direction: column;
          .name {
            color: map-get($mainColor, main-font-color);
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            margin-top: 16px;
          }
          .count {
            @include flexbox(flex-start, center);
            gap: 8px;
            color: map-get($mainColor, main-font-color);
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            margin-top: 8px;
            margin-bottom: 20px;
            span {
              color: #ffe882;
            }
          }

          .buttons {
            display: flex;
            text-align: center;
            a {
              color: rgba(0, 0, 0, 0.87);
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              text-wrap: nowrap;
              flex: 1 1 100px;
              padding: 8px 12px;
              background: linear-gradient(
                127deg,
                #ffb629 0%,
                #ffda56 50.72%,
                #ffd7a6 100%
              );
              &:first-child {
                border-radius: 12px 0px 0px 12px;
                border-right: 1px solid #18222d;
              }
              &:last-child {
                border-radius: 0px 12px 12px 0px;
              }
            }
          }
        }
      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 768.98px) {
  .statistics-page {
    .title-container {
      margin: 0 auto 30px auto;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
    }
    .other-influencers {
      .title-container {
        margin: 0 auto 30px auto;
        h1 {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }
}
