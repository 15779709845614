.services-page {
  .title-container {
    max-width: fit-content;
    margin: 0 auto 60px auto;
    h1 {
      color: map-get($mainColor, main-font-color);
      font-size: 58px;
      font-weight: 700;
      line-height: 68px;
      padding: 0 12px;
      text-align: center;
    }
    p {
      color: map-get($mainColor, main-font-color);
      font-size: 24px;
      font-weight: 600;
      padding-top: 12px;
      text-align: center;
    }
  }
  .main-content {
    display: flex;
    row-gap: 60px;
    flex-wrap: wrap;
    margin-top: 120px;
    .service-card {
      flex: 1 1 270px;
      text-align: center;
      padding: 0 20px;
      transition: 0.3s;
      i {
        font-size: 70px;
        color: map-get($mainColor, main-font-color-second);
      }
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
      h2 {
        color: map-get($mainColor, main-font-color);
        font-feature-settings: "clig" off, "liga" off;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        padding-top: 12px;
        padding-bottom: 18px;
        min-height: 80px;
      }
      p {
        color: #dedede;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-top: 28px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #498ea9;
      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 768.98px) {
  .services-page {
    .title-container {
      margin: 0 auto 30px auto;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .main-content {
      .service-card {
        h2 {
          min-height: unset !important;
        }
      }
    }
  }
}
