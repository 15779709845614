.section-agents-contacts {
  padding: 110px 0;
  .title-container {
    max-width: fit-content;
    margin: 0 auto 60px auto;
    h2 {
      color: map-get($mainColor, main-font-color);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
      line-height: 36px;
      padding: 0 12px;
    }
  }

  .agents-contacts {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    &-item {
      flex: 1 1 300px;
      @include flexbox(center, center);
      padding: 40px;
      border-radius: 12px;
      background: rgba(40, 100, 151, 0.13);
      backdrop-filter: blur(25px);
      a {
        @include flexbox(center, center);
        flex-wrap: wrap;
        gap: 16px;
        img {
          padding: 16px;
          border-radius: 50px;
          background: linear-gradient(148deg, #233454 18.4%, #4e73ba 118.73%);
        }
        span {
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
        }
      }
    }
  }
}
