.section-hero {
  height: calc(100vh - 250px);
  .container {
    position: relative;
    height: 100%;
    .content {
      @include flexbox(center, flex-start);
      flex-direction: column;
      height: 100%;
      padding: 0 48px;
      background-image: url(../assets/images/background/hero.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 12px;
      h1 {
        color: map-get($mainColor, main-font-color);
        font-size: 52px;
        font-weight: 700;
        line-height: 61px;
        max-width: 650px;
      }
      p {
        color: map-get($mainColor, main-font-color);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 24px;
        margin-bottom: 32px;
        max-width: 650px;
      }
      a {
        display: inline-block;
        padding: 8px 24px;
        color: #000;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        transition: all 0.3s ease-in;
        border-radius: 10px;
        background-color: map-get($mainColor, main-font-color-third);
        border: 1px solid transparent;
        &:hover {
          color: map-get($mainColor, main-font-color-third);
          border: 1px solid map-get($mainColor, main-font-color-third);
          background-color: transparent;
        }
      }
    }
    .youtube-wrapper {
      &-1 {
        position: absolute;
        top: 101px;
        right: 370px;
        z-index: 99;
        animation: scale-1 6s ease-in-out infinite;
      }
      &-2 {
        position: absolute;
        top: 241px;
        right: 173px;
        z-index: 99;
        transform: rotate(30deg);
        animation: scale-2 6s ease-in-out infinite;
      }
      &-3 {
        position: absolute;
        bottom: 141px;
        right: 50%;
        z-index: 99;
        transform: rotate(45deg);
        animation: scale-1 6s ease-in-out infinite;
      }
    }
    .bitcoin-wrapper {
      &-1 {
        position: absolute;
        top: 20px;
        right: 135px;
        animation: rotate-2 6s ease-in-out infinite;
      }
      &-2 {
        position: absolute;
        bottom: 147px;
        right: 432px;
        animation: rotate-1 6s ease-in-out infinite;
      }
    }
    .ethereum-wrapper {
      &-1 {
        position: absolute;
        top: -70px;
        right: 500px;
        animation: scale-1 6s ease-in-out infinite;
      }
      &-2 {
        position: absolute;
        bottom: 110px;
        right: 158px;
        animation: scale-1 6s ease-in-out infinite;
      }
    }
    .ethereum-wrapper-second {
      &-1 {
        position: absolute;
        bottom: -120px;
        right: 480px;
        animation: rotate-2 9s ease-in-out infinite;
      }
      &-2 {
        position: absolute;
        top: 175px;
        right: 50px;
        width: 100px;
        height: 100px;
        animation: scale-2 6s ease-in-out infinite;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (max-width: 768.98px) {
  .section-hero {
    .content {
      padding: 0 14px !important;
      background-position: left !important;
      h1 {
        font-size: 32px !important;
        line-height: 40px !important;
      }
      a {
        text-align: center;

        width: 100%;
      }
    }
    .bitcoin-wrapper {
      &-1 {
        top: 10px !important;
        right: 10px !important;
      }
    }
    .ethereum-wrapper-second {
      &-1 {
        bottom: -90px !important;
        left: -10px !important;
      }
    }
  }
}
