.section-partners {
  padding: 90px 0;
  h2 {
    color: map-get($mainColor, main-font-color);
    font-size: 38px;
    font-weight: 700;
    line-height: 48px;
    text-align: center;
    margin-bottom: 60px;
  }
  .swiper-slide {
    user-select: none;
    @include flexbox(center, center);
    gap: 25px;
    flex-direction: column;
    padding: 56px 20px;
    border-radius: 12px;
    background: linear-gradient(162deg, #162136 6.7%, #405f9c 208.25%);
    cursor: pointer;
    img {
      height: 120px;
      width: 120px;
      object-fit: cover;
      border-radius: 50%;
    }
    p {
      color: map-get($mainColor, main-font-color);
      font-size: 26px;
      font-weight: 700;
      line-height: 36px;
    }
  }
}
