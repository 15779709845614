.section-amount-main {
  padding: 90px 0;
  .content {
    @include flexbox(space-between, center);
    gap: 25px;
    flex-wrap: wrap;
    &-item {
      @include flexbox(center, center);
      flex-direction: column;
      gap: 15px;
      flex: 1 1 450px;
      padding: 65px;
      border-radius: 12px;
      background: rgba(31, 39, 46, 0.2);
      box-shadow: 0px 37px 115px 0px rgba(0, 0, 0, 0.25) inset,
        0px -17px 92px 0px rgba(255, 255, 255, 0.05) inset;
      backdrop-filter: blur(12px);
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 6px;
        background: linear-gradient(
          90deg,
          rgba(92, 107, 192, 0.02) 0%,
          #163e9a 30.5%,
          #252d9e 68.5%,
          rgba(116, 178, 205, 0) 96%
        );
        filter: drop-shadow(0px 16px 30px #184ba9);
      }
      p {
        @include flexbox(center, center);
        gap: 12px;
        span {
          font-size: 58px;
          font-weight: 700;
          line-height: 68px;
          &:first-child {
            color: #fff !important;
          }
          &:last-child {
            color: map-get($mainColor, main-font-color-third);
          }
        }
      }
      p {
        color: #666;
        font-size: 26px;
        font-weight: 400;
        line-height: 44px;
        text-align: center;
      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 768.98px) {
  .section-amount-main {
    .content {
      &-item {
        padding: 40px 0 !important;
      }
    }
  }
}
