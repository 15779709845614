.section-benefits {
  padding: 90px 0;
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    .ethereum_wrapper {
      position: absolute;
      top: 260px;
      left: -20px;
      animation: rotate-1 6s ease-in-out infinite;
    }
    .bitcoin_wrapper {
      position: absolute;
      top: 100px;
      right: -10px;
      animation: rotate-2 6s ease-in-out infinite;
    }
    .info-text {
      span {
        display: inline-block;
        color: #60717d;
        font-size: 26px;
        font-weight: 400;
        line-height: 36px;
      }
      h3 {
        color: #fff;
        font-size: 38px;
        font-weight: 700;
        margin-top: 12px;
        margin-bottom: 38px;
      }
    }

    .body {
      @include flexbox(center, center);
      flex-wrap: wrap;
      gap: 40px;
      .item {
        max-width: 320px;
        display: flex;
        flex-direction: column;
        border: 0.5px solid transparent;
        border-radius: 12px;
        background: linear-gradient(
          125deg,
          rgba(25, 64, 171, 0.13) 1.51%,
          rgba(22, 61, 169, 0) 95.34%
        );
        backdrop-filter: blur(37.20343017578125px);
        padding: 35px 20px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          transform: translateY(-10px);
          border: 0.5px solid #286497;
        }
        h2 {
          text-align: center;
          color: map-get($mainColor, main-font-color);
          font-size: 22px;
          font-weight: 700;
          line-height: 28px;
        }
        p {
          color: #dedede;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 768.98px) {
  .section-benefits {
    .content {
      .bitcoin_wrapper {
        top: 120px !important;
        right: -50px !important;
      }
      .ethereum_wrapper {
        top: unset !important;
        bottom: -170px !important;
        left: -20px;
      }
      .body {
        .item {
          flex: 1 1 320px !important;
          max-width: unset !important;
        }
      }
    }
  }
}
