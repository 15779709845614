.header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  transition: all 0.6s ease;
  padding: 19px 0;
  &.fixed {
    background-color: rgb(2 28 48 / 70%);
    padding: 13px 0;
    border-bottom: 0.2px solid rgba(255, 255, 255, 0.443);
  }
  .header_container {
    @include flexbox(space-between, center);
    .mobile_btn_open {
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        margin-top: 7px;
      }
    }
    .logo_container {
      width: 65px;
      height: 65px;
      object-fit: cover;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .nav_container {
      ul {
        @include flexbox(center, center);
        gap: 44px;
      }
      &_item {
        color: map-get($mainColor, main-font-color);
        font-size: 16px;
        font-weight: 400;
        transition: 0.3s ease-in-out;
        text-transform: uppercase;
        &:hover {
          color: #ffe882;
        }
        &.active {
          color: #FFE882;
        }
      }
    }

    .contact_container {
      transition: 0.2s ease-in;
      &:hover {
        transform: scale(1.1);
      }
      a {
        font-size: 40px;
      }
    }
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    padding: 35px 25px;
    background: linear-gradient(
      120deg,
      rgba(25, 64, 171, 0.13) 1.37%,
      rgba(22, 61, 169, 0) 103.77%
    );
    backdrop-filter: blur(37.20343017578125px);
    transition: all 0.3s ease-out;
    transform: translateX(-100vw);
    a {
      color: rgba(255, 255, 255, 0.6);
    }
    .sidebar_body {
      margin-top: 120px;
      li {
        padding: 15px 0;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        a {
          color: #fff;
          text-transform: uppercase;
          &.active {
            color: #FFE882 !important;
          }
        }
      }
    }
  }
}

// SIDEBAR
#show {
  .sidebar {
    transform: translateX(0vw);
    min-width: 80%;
    &_header {
      .close_button {
        font-size: 31px;
        color: #fff;
        float: right;
      }
    }
    .menu-inner {
      top: 0;
      height: calc(100% - 50px);
      padding-top: 50px;
      .menu-item {
        padding: 40px 30px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .mobile_btn_open {
    display: block;
  }

  .nav_container {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .mobile_btn_open {
    display: none !important;
  }
}
