.services-amount-body {
  padding-top: 200px;
  padding-bottom: 150px;
  .container {
    position: relative;
    .bitcoin_wrapper,
    .ethereum_wrapper {
      position: absolute;
    }
    .bitcoin_wrapper {
      left: 0;
      top: 60px;
      animation: rotate-1 6s ease-in-out infinite;
    }
    .ethereum_wrapper {
      bottom: 20px;
      right: 0;
      animation: rotate-2 6s ease-in-out infinite;
    }
  }
  .services-amount-wrapper {
    @include flexbox(space-between, normal);
    gap: 24px;
    flex-wrap: wrap;
    .item-container {
      z-index: 99;
      padding: 25px 50px;
      flex: 1 1 200px;
      user-select: none;
      @include flexbox(space-between, center);
      flex-direction: column;
      border-radius: 12px;
      background: rgba(31, 39, 46, 0.2);
      box-shadow: 0px 37px 115px 0px rgba(0, 0, 0, 0.25) inset,
        0px -17px 92px 0px rgba(255, 255, 255, 0.05) inset;
      backdrop-filter: blur(12px);
      transition: 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        transform: translateY(-8px) !important;
      }
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
      h3 {
        display: flex;
        gap: 12px;
        color: map-get($mainColor, main-font-color);
        font-size: 38px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 10px;
        p {
          color: map-get($mainColor, main-font-color-third);
          font-size: 38px;
          font-weight: 700;
          line-height: 48px;
        }
        @media screen and (max-width: 576px) {
          font-size: 26px;
        }
      }
      p {
        color: #666;
        font-size: 26px;
        font-weight: 400;
        line-height: 36px;
        text-wrap: nowrap;
      }
    }
  }
}


@media screen and (max-width: 768.98px) {
  .services-amount-body {
    padding-top: 120px;
    padding-bottom: 70px;
    .bitcoin_wrapper {
      top: -60px !important;
      left: -50px !important;
    }
    .ethereum_wrapper {
      bottom: -120px !important;
      right: -10px !important;
    }
  }
}