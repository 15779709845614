.about-page {
  .title-container {
    max-width: fit-content;
    margin: 0 auto 60px auto;
    h1 {
      color: map-get($mainColor, main-font-color);
      font-size: 58px;
      font-weight: 700;
      line-height: 68px;
      padding: 0 12px;
      text-align: center;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    img {
      width: 100%;
      max-height: 600px;
      object-fit: cover;
    }

    .main-content-wrapper {
      text-align: center;
      p {
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 20px;
      }
      .main-content {
        p {
          color: #dedede;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 768.98px) {
  .about-page {
    .title-container {
      margin: 0 auto 30px auto;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .main-content {
      text-align: justify;
    }

    &-body {
      img {
        min-height: 200px;
        object-fit: cover;
      }
    }
  }
}
