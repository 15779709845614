.section-information {
  padding: 40px 0;
  .content {
    @include flexbox(space-between, center);
    gap: 40px;
    flex-wrap: wrap;
    &-left {
      flex: 1 1 450px;
      span {
        color: #60717d;
        font-size: 26px;
        font-weight: 400;
        line-height: 36px;
      }
      h2 {
        color: map-get($mainColor, main-font-color);
        font-size: 38px;
        font-weight: 700;
        line-height: 48px;
        margin-top: 12px;
        margin-bottom: 65px;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 100px;
        li {
          @include flexbox(flex-start, center);
          gap: 12px;
          span {
            position: relative;
            font-size: 22px;
            font-weight: 700;
            line-height: 28px;
            color: map-get($mainColor, main-font-color);
            transition: all 0.3s ease-in;
            &::after {
              position: absolute;
              left: -45px;
              top: 60px;
              content: "";
              width: 3px;
              height: 70px;
              background: linear-gradient(90deg, #274bb0 0%, #10204a 100%);
            }
          }
          &:last-child {
            span {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }

    &-right {
      flex: 1 1 450px;
      margin-top: 180px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 768.98px) {
  .section-information {
    .content {
      &-left {
        ul {
          gap: 70px;
          li {
            span {
              &::after {
                top: 55px;
                height: 50px;
              }
            }
          }
        }
      }

      &-right {
        margin-top: 80px;
      }
    }
  }
}
