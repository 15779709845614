.section-customer {
  padding-top: 90px;
  padding-bottom: 50px;
  .reviews {
    &-header {
      span {
        color: #546673;
        font-size: 26px;
        font-weight: 400;
        line-height: 36px;
      }
      h2 {
        color: map-get($mainColor, main-font-color);
        font-size: 38px;
        font-weight: 700;
        line-height: 48px;
        margin-top: 12px;
        margin-bottom: 38px;
      }
    }

    &-body {
      .swiper-container {
        padding-top: 100px;
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
        .swiper-slide {
          position: relative;
          border-radius: 12px;
          background: #25375a;
          padding: 55px 16px;
          backdrop-filter: blur(37.20343017578125px);
          img {
            position: absolute;
            width: 98px;
            height: 98px;
            top: -32%;
            left: 50%;
            transform: translate(-50%, 50%);
            z-index: 9999;
            object-fit: cover;
            border-radius: 50%;
          }
          .user-info {
            @include flexbox(space-between, center);
            flex-direction: column;
            h2 {
              color: map-get($mainColor, main-font-color-third);
              font-size: 22px;
              font-weight: 700;
              line-height: 28px;
            }
            p {
              margin: 15px 0;
              color: var(--White-medium, rgba(255, 255, 255, 0.87));
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
            }
            .rate {
              color: map-get($mainColor, main-font-color-third);
            }
          }
        }
        .swiper-pagination {
          position: absolute;
          bottom: 5px;
          .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
            &-active {
              background: #0f3765;
              border: 3px solid #011c30;
              outline: 2px solid #0f3765;
            }
          }
        }
      }
      .swiper-container .swiper-slide {
        opacity: 0.4;
        transition: opacity 0.3s ease, transform 0.3s ease;
      }

      .swiper-container .swiper-slide-active {
        opacity: 1;
        transform: scale(1.05);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
        border: 0.7px solid #ffdd4584;
      }
    }
  }
}
