.contact-page {
  .title-container {
    max-width: fit-content;
    margin: 0 auto 60px auto;
    h1 {
      color: map-get($mainColor, main-font-color);
      font-size: 58px;
      font-weight: 700;
      line-height: 68px;
      padding: 0 12px;
      text-align: center;
    }
  }

  .contact-form {
    border-radius: 12px;
    background: rgba(40, 100, 151, 0.13);
    backdrop-filter: blur(25px);
    padding: 40px 50px;
    form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .input-item {
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex: 1 1 300px;

        label {
          @include flexbox(flex-start, center);
          color: map-get($mainColor, main-font-color);
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          span {
            color: map-get($mainColor, main-font-color-third);
            margin-left: 6px;
            margin-top: 6px;
          }
        }
      }
      .form-group {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
        input {
          padding: 15px;
          color: #fff;
          transition: 0.3s ease-in;
          border-radius: 12px;
          border: 1px solid #4e4e4f;
          &::placeholder {
            color: #dedede;
          }
          &:focus {
            border: 1px solid #bababa;
          }
        }
      }
      .message {
        textarea {
          padding: 15px;
          color: #fff;
          transition: 0.3s;
          border-radius: 12px;
          border: 1px solid #4e4e4f;
          &::placeholder {
            color: #dedede;
          }
          &:focus {
            border: 1px solid #bababa;
          }
        }
      }
      input[type="submit"] {
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        margin: 30px auto 0 auto;
        padding: 12px 48px;
        border-radius: 12px;
        background: linear-gradient(
          127deg,
          #ffb629 0%,
          #ffda56 50.72%,
          #ffd7a6 100%
        );
      }
    }
  }
}

// RESPONSIVE
@media screen and (max-width: 768.98px) {
  .contact-page {
    .title-container {
      margin: 0 auto 30px auto;
      h1 {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .contact-form {
      padding: 20px 12px;
      input[type=submit] {
        margin: unset !important;
      }
    }

    .section-agents-contacts {
      padding-top: 110px;
      padding-bottom: 40px;
      .agents-contacts-item {
        padding: 30px;
      }
    }
  }
}
