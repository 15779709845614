// ABSTARCT
@import "./abstract/mixins";
@import "./abstract/variables";

// BASE
@import "./base/animations";
@import "./base/reset";
@import "./base/typography";

// COMPONENTS
@import "./components/hero";
@import "./components/benefits";
@import "./components/loader";

@import "./components/amount";
@import "./components/amountMain";
@import "./components/solutions";
@import "./components/testimonial";
@import "./components/partners";
@import "./components/cryptoAgents";






// LAYOUT
@import "./layouts/footer";
@import "./layouts/navbar";

// PAGES
@import "./pages/about";
@import "./pages/contact";
@import "./pages/influencer";
@import "./pages/notFound";
@import "./pages/services";
@import "./pages/statistics";




.page-main {
    padding-top: 100px;
}
// VENDORS
// @import "./vendors/bootstrap.min.css";